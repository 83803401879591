import { useCallback } from "react"
import { GoogleAuthProvider, signInWithPopup } from "@firebase/auth"
import { auth } from "../firebase/config"
import Button from "@mui/material/Button"

export function SignIn() {
  const onClick = useCallback(() => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result)
        // const token = credential?.accessToken
        // The signed-in user info.
        // const user = result.user
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        // const email = error.email
        // // The AuthCredential type that was used.
        // const credential = GoogleAuthProvider.credentialFromError(error)
        // ...
        console.warn(errorCode, errorMessage)
      })
  }, [])

  return auth.currentUser ? null : (
    <div style={{ padding: "20px" }}>
      <Button variant="contained" onClick={onClick}>
        Sign in with Google
      </Button>
    </div>
  )
}

export function SignOut() {
  return (
    auth.currentUser && (
      <div style={{ padding: "20px" }}>
        <Button variant="contained" onClick={() => auth.signOut()}>
          Sign Out
        </Button>
      </div>
    )
  )
}
