import React, { useContext } from "react"
import { SignIn, SignOut } from "../common/authentication"
// import { SignIn, SignOut } from "../common/Authentication"
import { AuthContext } from "../common/context/AuthContext"

export function UserInfo() {
  const { user } = useContext(AuthContext)

  return (
    <div className={"center-screen"}>
      {user?.user ? (
        <>
          <img src={user.user?.photoURL ?? undefined} alt="" />
          <header>{user.user?.displayName}</header>
          <header>{user.user?.email}</header>
          <SignOut />
        </>
      ) : (
        <SignIn />
      )}
    </div>
  )
}
