import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Title from "../Title"
import { useCallback, useState } from "react"
import { OtaFileCollectionType, ProductSku } from "../../../common/Variables"
import { getStorage, ref, getDownloadURL } from "firebase/storage"
import { ArrowDropDown } from "@mui/icons-material"
import { FileMenuProps, OtaFileMenu } from "../../common/Menus"
import { useGetFirestoreCollectionDocs } from "../../../common/helpers/useGetFirestoreCollectionDocs"

const storage = getStorage()

// TODO File Upload, remove, edit function
// TODO Add public OTA link
export const OtaFiles = React.memo(() => {
  const [otaFiles, getOtaFiles] = useGetFirestoreCollectionDocs(
    "ota-files"
  ) as OtaFileCollectionType

  // Menu
  const [fileMenu, setFileMenu] = useState<Partial<FileMenuProps>>({
    fileName: null,
    anchor: null,
  })

  const doFileMenuClose = useCallback(() => {
    setFileMenu({ ...fileMenu, anchor: null })
    getOtaFiles()
  }, [fileMenu, getOtaFiles])

  const doFileMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, item: string) => {
      setFileMenu({
        fileName: item,
        anchor: event.currentTarget,
        handleClose: doFileMenuClose,
      })
    },
    [doFileMenuClose]
  )

  const downloadFile = useCallback(async (fileName: string) => {
    const url = await getDownloadURL(ref(storage, `ota-files/${fileName}`))
    window.location.assign(url)
    return
  }, [])
  console.log(otaFiles)

  return (
    <React.Fragment>
      <Title>OTA Files</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Filename</TableCell>
            <TableCell>FW version</TableCell>
            <TableCell>Products</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otaFiles.map((row, index) => (
            <TableRow key={`${index}`}>
              <TableCell
                sx={{ cursor: "pointer" }}
                onClick={() => downloadFile(row.data.fileName)}
              >{`${row.data.fileName}`}</TableCell>
              <TableCell>{`${row.data.fwVersion}`}</TableCell>
              <TableCell>
                {row.data.productList
                  ?.map((product: any) => ProductSku[product])
                  .join(", ")}
              </TableCell>
              <TableCell align="right">
                <div
                  onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    doFileMenuOpen(event, row.id)
                  }
                >
                  <ArrowDropDown />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <OtaFileMenu {...fileMenu} refetch={getOtaFiles} />
    </React.Fragment>
  )
})
