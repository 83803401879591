import { CircularProgress } from "@mui/material"
import React, { useContext } from "react"
import { AuthContext } from "./common/context/AuthContext"
import { DashboardContent } from "./components/dashboard/Dashboard"
import { UserInfo } from "./components/UserInfo"

// TODO Add update file download option with logging
export const RidApp = () => {
  const { user } = useContext(AuthContext)

  if (!user) {
    return (
      <div className={"center-screen"}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <>
      <div className="App">
        {user?.roles?.includes("admin" as any) ? (
          <DashboardContent />
        ) : (
          <UserInfo />
        )}
      </div>
    </>
  )
}
