import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Title from "../Title"
import { ProductSku, RidDeviceCollectionType } from "../../../common/Variables"
import { useGetFirestoreCollectionDocs } from "../../../common/helpers/useGetFirestoreCollectionDocs"

// TODO Open more info
// TODO Add public OTA download link compiled for each Device
export function RidDevices() {
  const [devices] = useGetFirestoreCollectionDocs(
    "devices"
  ) as RidDeviceCollectionType

  return (
    <React.Fragment>
      <Title>Devices</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Model</TableCell>
            <TableCell>SN</TableCell>
            <TableCell>MAC</TableCell>
            <TableCell align="right">ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {devices
            .sort((a, b) => {
              if (!a.data.dateFlashed || !b.data.dateFlashed) {
                return 0
              } else if (a.data.dateFlashed > b.data.dateFlashed) {
                return 1
              } else {
                return -1
              }
            })
            .map((row, index) => (
              <TableRow key={index}>
                <TableCell>{`${row.data.dateFlashed
                  ?.toDate()
                  .toDateString()}`}</TableCell>
                <TableCell>{`${ProductSku[row.data.prodType]}`}</TableCell>
                <TableCell>{`${row.data.devSn}`}</TableCell>
                <TableCell>
                  <code>{`${row.data.devMac}`}</code>
                </TableCell>
                <TableCell align="right">
                  <code>{`${row.data.remoteId}`}</code>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
