import { AuthProvider } from "./app/common/context/AuthContext"
import { RidApp } from "./app/RidApp"
import "./App.css"

export default function App() {
  return (
    <AuthProvider>
      <RidApp />
    </AuthProvider>
  )
}
