import * as React from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Title from "../Title"
import { useGetFirestoreCollectionDocs } from "../../../common/helpers/useGetFirestoreCollectionDocs"
import { SiteUserCollectionType } from "../../../common/Variables"

// TODO User role management, delete, disable users
export function SiteUsers() {
  const [users] = useGetFirestoreCollectionDocs(
    "users"
  ) as SiteUserCollectionType

  return (
    <React.Fragment>
      <Title>Users</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell>Roles</TableCell>
            <TableCell>UID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((row, index) => (
            <TableRow key={`${index}`}>
              <TableCell>{`${row.data.email ?? ""}`}</TableCell>
              <TableCell>{`${row.data.roles ?? ""}`}</TableCell>
              <TableCell>{`${row.id}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  )
}
