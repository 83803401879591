import React, { useEffect, useState } from "react"
import { User } from "@firebase/auth"
import { auth } from "../../firebase/config"
// import { useAuthState } from 'react-firebase-hooks/auth'

export interface AuthContextInterface {
  user: {
    user: User | null | undefined
    roles: UserRoles[] | null | undefined
  } | null
}

export enum UserRoles {
  "admin",
}

export const AuthContext = React.createContext<AuthContextInterface>({
  user: null,
})

export const AuthProvider = ({ children }: { children: any }) => {
  const [cxUser, setCxUser] = useState<AuthContextInterface>({
    user: null,
  })

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        setCxUser({ user: { user: undefined, roles: [] } })
      } else {
        user?.getIdTokenResult().then((result) => {
          const roles = result.claims?.roles as UserRoles[] | null | undefined
          setCxUser({ user: { user, roles: roles } })
        })
      }
    })
  }, [])

  return <AuthContext.Provider value={cxUser}>{children}</AuthContext.Provider>
}
