import { collection, getDocs, getFirestore } from "firebase/firestore/lite"
import { useCallback, useEffect, useRef, useState } from "react"
import { app } from "../../firebase/config"

const DEBOUNCE_TIME = 1000

export function useGetFirestoreCollectionDocs(
  collectionName: string
): [{ id: string; data: any }[], () => void] {
  const [collectionDocs, setCollectionDocs] = useState<
    | {
        id: string
        data: any
      }[]
    | []
  >([])
  const debounced = useRef(true)

  const getCollectionDocs = useCallback(() => {
    if (!debounced.current) return
    debounced.current = false

    getDocs(collection(getFirestore(app), collectionName))
      .then((value) =>
        setCollectionDocs(
          value.docs.map((doc) => ({ data: doc.data(), id: doc.id }))
        )
      )
      .catch((reason) => setCollectionDocs([]))

    setTimeout(() => {
      debounced.current = true
    }, DEBOUNCE_TIME)
  }, [debounced, collectionName])

  useEffect(() => {
    console.debug("getCollectionDocs")
    getCollectionDocs()
  }, [getCollectionDocs])

  return [collectionDocs, getCollectionDocs]
}
