import { initializeApp } from "@firebase/app"
import { getAuth } from "@firebase/auth"
// import { getFirestore } from 'firebase/firestore/lite'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCsigp7jHg-O7Jz6Z5Bm2k65wdqE7680-o",
  authDomain: "remote-id-net.firebaseapp.com",
  projectId: "remote-id-net",
  storageBucket: "remote-id-net.appspot.com",
  messagingSenderId: "33586732142",
  appId: "1:33586732142:web:9dcb28f68c1d0b7e725a7f",
  measurementId: "G-NWXNPJ1K38",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

// export const provider = new GoogleAuthProvider()
// const analytics = getAnalytics(app)
// export const firestore = getFirestore(app)
