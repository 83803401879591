import { useCallback, useEffect, useState } from "react"
import { Menu, MenuItem } from "@mui/material"
import { Edit, Delete } from "@mui/icons-material"
import { DeleteWarning } from "./Dialogs"
import { deleteDoc, doc, getFirestore } from "firebase/firestore/lite"
import { app } from "../../firebase/config"

export type FileMenuProps = {
  fileName: string | null
  anchor: HTMLElement | null
  handleClose: () => void
  refetch?: () => void
}

export function OtaFileMenu({
  fileName,
  anchor,
  handleClose,
  refetch,
}: Partial<FileMenuProps>) {
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false)
  const [file, setFile] = useState(fileName)

  const onDeleteFile = useCallback(async () => {
    console.log(`DELETE ${file}`)
    doc(getFirestore(app), `/ota-files/${file}`)
    deleteDoc(doc(getFirestore(app), `/ota-files/${file}`)).then((value) =>
      refetch?.()
    )
  }, [file, refetch])

  useEffect(() => {
    if (anchor) {
      setFile(fileName)
    }
  }, [fileName, anchor])

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={!!anchor}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleClose}>
          <Edit />
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteWarning(true)
            handleClose?.()
          }}
        >
          <Delete />
        </MenuItem>
      </Menu>

      <DeleteWarning
        open={openDeleteWarning}
        handleClose={() => {
          handleClose?.()
          setOpenDeleteWarning(false)
        }}
        handleSubmit={() => {
          onDeleteFile()
          handleClose?.()
          setOpenDeleteWarning(false)
        }}
        itemName={file ?? ""}
      />
    </>
  )
}
