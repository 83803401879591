import { Timestamp } from "firebase/firestore/lite"

export enum ProductId {
  "RID-FR" = 1,
  "RID-NAKED",
}

export const ProductSku = ["", "RID-FR", "RID-NAKED"]

/**************************************************************
 * OTA Files
 *************************************************************/
export interface OtaFile {
  fileName: string
  fwVersion: string
  cpu: RidCpu[]
  productList: number[]
  products: { [key: string]: OtaProduct }
}

type RidProductId = 1 | 2
type RidCpu = "esp32" | "esp32s2"

export interface OtaProduct {
  productId: RidProductId
  pcba: number[]
}

export type GetOtaFileListProps = {
  fwVersion?: string
  productId?: RidProductId
  cpu?: RidCpu
  pcba?: number
  dateReleased: Timestamp | null
  // TODO rev
}

export type OtaFileCollectionType = [
  { id: string; data: OtaFile }[],
  () => void
]

/**************************************************************
 * Devices
 *************************************************************/
export interface RidDevice {
  devSn: string
  devMac: string
  dateFlashed: Timestamp | null
  pcbaId: number
  pcbaRev: number
  prodType: number
  ridStandard: number
  idType: number
  remoteId: string
  wifiSsid: string
  wifiPw: string
  secureBootKey: string
  otaKey: string
  gpsSn: string
  fw: string
  notes: string
  timeAdded: Timestamp | null
}

export type RidDeviceCollectionType = [
  { id: string; data: RidDevice }[],
  () => void
]

/**************************************************************
 * Site users
 *************************************************************/
export interface SiteUser {
  email: string
  roles: string[]
  timeSignUp: Timestamp
}

export type SiteUserCollectionType = [
  { id: string; data: SiteUser }[],
  () => void
]
